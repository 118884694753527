
    .info_box {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        padding: 64px 0;

        .info_two_box {
            width: 1224px;
            background-color: #00556A;
            border-radius: 8px;

            .introduction_title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 55px;

                .text {
                    font-size: 40px;
                    color: #fff;
                    padding: 0 30px
                }

                .border {
                    width: 80px;
                    height: 2px;
                    background: #FFFFFF;
                    opacity: 1;
                }
            }
            .introduction_two_title {
                font-size: 36px;
                color: #F5F7FA;
                text-align: center;
                margin-top: 20px;
            }
            .introduce_box {
                display: flex;
                /*justify-content: space-between;*/
                margin-top: 65px;
                margin-bottom: 103px;

                .introduce_left {
                    margin-left: -72px;
                }

                .introduce_right {
                    min-width: 567px;
                    font-size: 16px;
                    color: #FFFFFF;
                    padding: 0 48px;

                    .introduce_right_width1 {
                        display: block;
                        font-size: 28px;
                        color: #FFFFFF;
                        margin-top: 13px;
                    }
                    .introduce_right_width2 {
                        display: block;
                        font-size: 16px;
                        color: #FFFFFF;
                        margin-top: 45px;
                    }
                }
            }
        }
    }
