
.nav-container {
    transition: all .5s;
    &.nav-fix {
        position: fixed;
        background-color: #FFFFFF;
        .nav-content {
            .nav-logo {
                background-image: url("../../assets/images/yiyatong_logo.png");
            }
        }
        .nav-link {
            .a-link {
                color: rgba(12, 26, 30, 1);
            }
        }
        .nav-btn {
            :deep(.backend-btn) {
                .el-dropdown-link {
                    cursor: pointer;
                    color: rgba(12, 26, 30, 1);
                    font-size: 18px;
                }
            }
        }
    }
    position: absolute;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    .nav-content {
        width: 1224px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-logo {
            width: 195px;
            height: 56px;
            background-image: url("../../assets/images/yyttong_bg_fff.png");
            background-size: cover;
        }
    }
    .nav-link {
        .a-link {
            cursor: pointer;
            font-size: 18px;
            color: #FFFFFF;
            margin-left: 114px;
            &:first-child {
                margin-left: 0px;
            }
        }
    }
    .nav-btn {
        :deep(.backend-btn) {
            .el-dropdown-link {
                cursor: pointer;
                color: #FFFFFF;
                font-size: 18px;
            }
        }
    }
}
