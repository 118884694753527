
.index-container-yyt {
    &.el-scrollbar {
        :deep(.el-scrollbar__wrap) {
            overflow-x: hidden;
        }
    }
    .nav-banner {
        position: relative;
        height: 720px;
    }
}

