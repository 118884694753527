
.footer {
    width: 100%;
    height: 83px;
    background-color: rgba(51, 51, 51, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-content {
        span {
            &.beian {
                margin-right: 24px;
            }
            font-size: 14px;
            color: rgba(255, 255, 255, 1)
        }
    }
}
