
.Banner {
    position: absolute;
    top: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 720px;
    .carousel-item {
        width: 100%;
        height: 100%;
    }
}
::v-deep .el-carousel__indicators--horizontal .el-carousel__indicator {
    .el-carousel__button {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
}
